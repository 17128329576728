import React from "react"
import { Row, Col } from "react-flexbox-grid"
import Hero from "../../../components/Hero"
import ContentBlock from "../../../components/ContentBlock"
import Layout from "../../../components/Layout"
import HelmetWrapper from "../../../components/HelmetWrapper"
import newsHero from "../../../assets/images/header_news.jpg"
import awardImg from "../../../assets/images/LSPA_award.jpg"
import circleImg from "../../../assets/images/sm_circle_ionvestors.png"
import partnerLogo1 from "../../../assets/images/partner_logo10.png"
import partnerLogo2 from "../../../assets/images/partner_logo09.png"
import partnerLogo3 from "../../../assets/images/partner_logo05.png"
import partnerLogo4 from "../../../assets/images/partner_logo02.png"
import partnerLogo5 from "../../../assets/images/partner_logo08.png"
import partnerLogo6 from "../../../assets/images/partner_logo04.png"
import partnerLogo7 from "../../../assets/images/partner_logo01.png"
import partnerLogo8 from "../../../assets/images/partner_logo11.png"
import partnerLogo9 from "../../../assets/images/partner_logo07.png"
import partnerLogo10 from "../../../assets/images/partner_logo06.png"
import partnerLogo11 from "../../../assets/images/partner_logo03.png"

import "./index.scss"

const pageTitle = "Investors"
const pageDescription =
  "We are a privately-owned biopharmaceutical company headquartered in Plymouth Meeting, PA. Learn more here."
const pageKeywords = "harmony biosciences, central nervous system disorders"

const investors = () => (
  <Layout jobCode="US-HAR-2200062/Aug 2022">
    <div id="investors">
      <HelmetWrapper
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageKeywords={pageKeywords}
        slug="/newsroom/investors"
      />
      <Hero bg={newsHero}>
        <h1>Investors</h1>
      </Hero>
      <ContentBlock color="white" className="email-contact">
        <p>
          <strong>For IR Inquiries:</strong>
          <br />
          <a
            style={{ wordBreak: "break-all", color: "blue" }}
            href="mailto:ir@harmonybiosciences.com"
          >
            ir@harmonybiosciences.com
          </a>
        </p>
      </ContentBlock>
      <ContentBlock color="green">
        <Row>
          <Col xs={12} md={2}>
            <Row center="xs">
              <Col>
                <img src={circleImg} className="" />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={10}>
            <p>
              Harmony Biosciences is a private pharmaceutical company
              headquartered in Plymouth Meeting, PA. The company was established
              in October 2017, with a vision to develop and provide new
              medications to help people who are living with rare, neurological
              diseases.
            </p>
            <p>
              In October 2017, the company acquired exclusive U.S. rights to
              develop, register and market the drug pitolisant from French
              company, Bioprojet SCR (Bioprojet) to address unmet needs for
              patients with sleep and other central nervous system disorders.
              Harmony’s financing has come from highly experienced equity
              investors including: Bioprojet; Fidelity Management and Research
              Company; HBM Healthcare Investments; Nan Fung LIfe Sciences; Novo
              Holdings; Paragon Biosciences, LLC; Valor Equity Partners; venBio
              Partners; Vivo Capital; Aisling Capital and Soros Fund Management.{" "}
            </p>{" "}
          </Col>
        </Row>
      </ContentBlock>
      <ContentBlock>
        <Row>
          <Col xs={12} md={6}>
            <img
              src={awardImg}
              alt="Harmony Biosciences receives Deal of the Year award from Life Sciences PA."
            />
          </Col>
          <Col xs={12} md={6}>
            <h2>Life Sciences Pennsylvania</h2>
            <p>
              <strong>Deal of the Year Award 2017</strong>
            </p>
            <p>
              Harmony received Life Sciences Pennsylvania’s 2017 Deal of the
              Year award for the largest private equity investment in the
              biotech industry in Pennsylvania to acquire the exclusive U.S.
              rights to develop, register and market pitolisant from French
              company Bioprojet SCR.{" "}
            </p>
          </Col>
        </Row>
      </ContentBlock>
      <ContentBlock color="blue">
        <h2>INVESTORS</h2>
        <Row>
          <Col xs={6} md={3}>
            <img src={partnerLogo1} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={partnerLogo2} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={partnerLogo3} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={partnerLogo4} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={partnerLogo5} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={partnerLogo6} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={partnerLogo7} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={partnerLogo8} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={partnerLogo9} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={partnerLogo10} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={partnerLogo11} alt="" />
          </Col>
        </Row>
      </ContentBlock>
    </div>
  </Layout>
)

export default investors
