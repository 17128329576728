import React from "react"
import { Row, Col } from "react-flexbox-grid"

import "./index.scss"

const Hero = ({ className, bg, col = 4, children }) => {
  return (
    <div
      className={`hero-content hero-${className}`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <Row bottom="xs" className="hero-white-box-container">
        <Col xs={12} md={col} className="white-box">
          {children}
        </Col>
      </Row>
    </div>
  )
}

export default Hero
