import React from "react"

import "./index.scss"

const ContentBlock = ({ id, className, color, children }) => {
  return (
    <div id={id} className={`content-block cb-${className} ${color}`}>
      {children}
    </div>
  )
}

export default ContentBlock
